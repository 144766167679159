
import './styles.scss';

$(function() {
	$(document).on('focusout', 'input#gc_sepa_input_iban', function() {

		var element = $(this);

		$.ajax({
			type: 'POST',
			headers: { "cache-control": "no-cache" },
			url: prestashop.urls.base_url + 'index.php' + '?controller=payment&fc=module&module=gc_sepa&rand=' + new Date().getTime(),
			data: 'ajax=1&submitCheckIban=1&iban='+ element.val(),
			success: function(result) {
				if (result == "1") {
					element.closest('.form-group').removeClass('has-error').addClass('form-ok');
				} else {
					element.closest('.form-group').addClass('has-error').removeClass('form-ok');
				}
			}
		});
	});

	$('#mandat_input').on('click', function(e) {
		e.preventDefault();

		$('.form-group .form-control').show();
		$('.form-group .form-label').hide();

		$('#gc_sepa_input_id').remove();

		$(this).parent().parent().hide();
	});

	$('#gc_sepa_input_iban').on('keydown', function() {
		var iban = $('#gc_sepa_input_iban').val()

		if(iban.substring(0,2).toLowerCase() != sepa_shop_iso) {
			$('#gc_sepa_input_bic').parent().addClass('required');

			if($('label[for=gc_sepa_input_bic] sup').length == 0) {
				$('label[for=gc_sepa_input_bic]').append('<sup>&nbsp;*</sup>');
			}
		} else {
			$('#gc_sepa_input_bic').parent().removeClass('required');
			$('label[for=gc_sepa_input_bic] sup').remove();
		}
	});
});
